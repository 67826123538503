import { ref, Ref } from 'vue';
import { ListHeaderItem } from '@/components/common/list';

const initData = () => {
    const isShowDeviceDialog = ref(false);
    const deviceDialogOperaType: Ref<OperateType> = ref('add');
    const headers: Array<ListHeaderItem> = [{
        name: 'MAC',
        label: WordList.TabelPersonDeviceInHtmlMAC
    }, {
        name: 'Owner',
        label: WordList.TabelPersonDeviceInHtmlOwner
    }, {
        name: 'SipAccount',
        label: WordList.MulListUpdateListTanleAccount
    }, {
        name: 'Location',
        label: WordList.TabelPersonDeviceInHtmlLocation
    }, {
        name: 'Device Type',
        label: WordList.TabelPersonDeviceInHtmlDeviceTypeLabel,
        type: 'customize'
    }, {
        name: 'Status',
        label: WordList.MulListUpdateListTanleStatus,
        type: 'customize'
    }, {
        name: 'BindingTime',
        label: WordList.PersonaldeviceListTanleBindingTime
    }];
    const searchKeyList = [{
        label: WordList.TabelPersonDeviceInHtmlMAC,
        name: 'MAC'
    }, {
        label: WordList.TabelPersonDeviceInHtmlLocation,
        name: 'Location'
    }];
    return {
        isShowDeviceDialog,
        deviceDialogOperaType,
        headers,
        searchKeyList
    };
};

export default null;
export {
    initData
};