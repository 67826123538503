
import deviceIcon from '@/components/view/common/device-icon/index.vue';
import deviceStatus from '@/components/view/common/device-status/index.vue';
import autopDialog from '@/components/view/common/dialog/autop/index';
import deviceSetDialog
    from '@/components/view/installer/device-set-dialog/index';
import { defineComponent, reactive, ref } from 'vue';
import {
    listPagination, RequestData, getSearch, ListActionItem
} from '@/components/common/list';
import addButton from '@/components/common/add-button/index.vue';
import router from '@/router';
import { installerBaseRouter, installerSubRouter } from '@/router/data';
import HttpRequest from '@/util/axios.config';
import remove from '@/methods/remove-func';
import { deviceApi } from '@/api';
import {
    initData
} from '../composables/deviceutil';

export default defineComponent({
    components: {
        listPagination,
        addButton,
        deviceIcon,
        deviceStatus,
        deviceSetDialog,
        autopDialog
    },
    props: {
        account: {
            type: String
        },
        familySip: {
            type: String
        },
        // 是否选择高级功能 0关 1开
        premiumPlan: {
            type: Number,
            default: 0
        }
    },
    setup(props) {
        const {
            isShowDeviceDialog,
            deviceDialogOperaType,
            headers,
            searchKeyList
        } = initData();
        const updateToList = ref(0);
        const saveParamInPath = ref(false);
        const searchList = () => {
            updateToList.value += 1;
            saveParamInPath.value = true;
        };
        const listRequestData: RequestData = reactive({
            url: 'v3/web/single/device/getList',
            param: getSearch({
                User: '',
                searchKey: 'MAC',
                searchValue: ''
            })
        });
        if (props.account) listRequestData.param.User = props.account;
        const formData = ref({
            ID: '',
            Node: String(props.account),
            MAC: '',
            Type: '2',
            Location: '',
            Relay: '',
            Special: '0',
            SecurityRelay: ''
        });
        const addDevice = () => {
            formData.value = {
                ID: '',
                Node: String(props.account),
                MAC: '',
                Type: '2',
                Location: '',
                Relay: '',
                Special: '0',
                SecurityRelay: ''
            };
            deviceDialogOperaType.value = 'add';
            isShowDeviceDialog.value = true;
        };
        const edit = (listItem: FormData) => {
            const obj = JSON.parse(JSON.stringify(listItem));
            formData.value = {
                ID: obj.ID,
                Node: obj.Account,
                MAC: obj.MAC,
                Type: obj.Type,
                Location: obj.Location,
                Relay: obj.Relay,
                Special: obj.Special,
                SecurityRelay: obj.SecurityRelay ? obj.SecurityRelay : ''
            };
            deviceDialogOperaType.value = 'edit';
            isShowDeviceDialog.value = true;
        };
        const goToDeviceInfo = (item: object) => {
            localStorage.setItem('detail', JSON.stringify(item));
            router.push(`/${installerBaseRouter}/${installerSubRouter.devicedetail}`);
        };

        const action: ListActionItem[] = [{
            type: 'info2',
            event: 'info',
            class: 'el-icon-my-info info-icon',
            title: WordList.PersonuserInfo
        }, {
            type: 'edit2',
            event: 'edit',
            class: 'el-icon-my-modify modify-icon',
            title: WordList.TabelConfigInHtmlModify
        }, {
            type: 'delete2',
            event(data: { ID: string }) {
                remove(WordList.TabelDeleteText, () => {
                    deviceApi.delete({
                        ID: data.ID
                    }, () => {
                        updateToList.value += 1;
                    });
                });
            },
            class: 'el-icon-delete delete-icon',
            showCondition(val: { Special: string }) {
                // 特定室内机没有删除功能
                return val.Special === '0';
            },
            title: WordList.TabelConfigInHtmlDelete
        }];

        const isShowAutopDialog = ref(false);
        const autopInitData = {
            insAccount: props.familySip ? props.familySip : ''
        };
        return {
            headers,
            searchKeyList,
            listRequestData,
            updateToList,
            goToDeviceInfo,
            deviceDialogOperaType,
            isShowDeviceDialog,
            addDevice,
            formData,
            edit,
            searchList,
            saveParamInPath,
            action,
            isShowAutopDialog,
            autopInitData
        };
    }
});
